<template>

  <div>

    <candidate-list-add-new
      :job-id="jobId"
      @refreshParent="updateParent"
    />

    <!-- Filters -->
    <!---<candidates-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    />-->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                id="filter-input"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <!--<b-button
                variant="primary"
                @click="toggle"
              >
                <span class="text-nowrap">Add Candidate</span>
              </b-button>-->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refCandidateListTable"
        class="position-relative"
        :items="fetchCandidates"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :filter="filter"
        :filter-included-fields="filterOn"
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :current-page="currentPage"
        :per-page="perPage"
      
        @filtered="onFiltered"
        
      >
        
        <!-- Column: Candidate -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            
            <b-link
              :to="{ name: 'apps-candidates-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.first_name }} {{data.item.last_name}}<br>
              SHC-{{ (parseInt(data.item.id) + 1000) }}
            </b-link>
            <!--<small class="text-muted">@{{ data.item.candidatename }}</small>-->
          </b-media>
        </template>

        <!-- Column: Contact -->
        <template #cell(email)="data">
          <div class="text-nowrap">
            
            <span class="align-text-top">{{ data.item.email }}</span><br>
            <span class="align-text-top">{{ data.item.contact_no }}</span>
          </div>
        </template>

        <!-- Column: Location -->
        <template #cell(curr_location)="data">
          <div class="text-nowrap">
            {{ data.item.curr_location }}
          </div>
        </template>
        <!-- Column: Location -->
        <template #cell(source)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize" v-if="data.item.jobs[0]">{{ JSON.parse(data.item.jobs[0].candidate_jobs.addedBy)[0].title }}</span>
            <span class="align-text-top text-capitalize" v-else>{{ JSON.parse(data.item.created_by)[0].title }}</span><br>
            <span class="align-text-top text-capitalize" v-if="data.item.jobs[0]">{{ data.item.jobs[0].candidate_jobs.source }}</span>
            <span class="align-text-top text-capitalize" v-else>{{ data.item.source }}</span>
          </div>
        </template>
         <!-- Column: Status -->
         <template #cell(status)="data">
          <b-badge
            pill
            class="text-capitalize"
            :class="resolveCandidateStatusVariant(data.item.jobs[0].candidate_jobs.candidateStatus)"
            v-if="data.item.jobs[0]"
          >
            {{ data.item.jobs[0].candidate_jobs.candidateStatus}}
          </b-badge>
          <b-badge
            pill
            :class="resolveCandidateStatusVariant(data.item.status)"
            v-else
          >
            {{ data.item.status  }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-candidates-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-row>
        <b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
      </b-row>      
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import CandidateListAddNew from './CandidateListAddNew.vue'
//import { toRefs, toRef } from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import JobService from '@/services/job-service'
import CandidateService from '@/services/candidate-service'


export default {
  data() {
    return {
      jobId: this.jobId,
      all: this.all,
      fetchCandidates: [],
      tableColumns : [
    { key: 'id', sortable: true, Label: 'Title' },     
    //{ key: 'first_name', sortable: true },
    { key: 'email', sortable: true, label: 'Contact' },
    //{ key: 'contact_no', label: 'Contact', sortable: true },
    {
      key: 'curr_location',
      label: 'Location',
      formatter: true,
      sortable: true,
    },
    {
      key: 'source',
      label: 'Sourced',
      formatter: true,
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ],
  //tableColumns: ['id', 'first_name', 'last_name', 'contact_no', 'email', 'curr_location', 'source', 'status', 'actions' ],
  //const jobId: '')
  totalRows: 1,
  currentPage: 1,
  perPage: 25,
  pageOptions: [10, 25, 50, 100],
  totalCandidates: 0,
  //currentPage: 1,
  //perPageOptions: [10, 25, 50, 100],
  searchQuery: '',
  sortBy: 'id',
  isSortDirDesc: true,
  from: 0,
  to: 0,
  of: 0,
  isAddNewCandidateSidebarActive: false,
  filter: null,
  filterOn: [],
    }
  },
  
  components: {
    //CandidatesListFilters,
    CandidateListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  
  created() {
    
      this.getAllCandidates()
    
    //this.dataMeta = computed(() => {
    const localItemsCount = this.fetchCandidates ? this.fetchCandidates.length : 0;
    //return {
      this.from = this.perPage.value * (this.currentPage.value - 1) + (localItemsCount ? 1 : 0);
      this.to = this.perPage.value * (this.currentPage.value - 1) + localItemsCount;
      this.of = this.fetchCandidates.length;
    //}
  //})
  },
  methods: {
    getAllCandidates() {
      CandidateService.getAllCandidates('null').then(res => {
        console.log(res.data)
        if(res.status === "OK") {
          console.log("in if", res.status)
          this.fetchCandidates = res.data
          
        } else {
          console.log("Failed")
        }
      })
    },
    resolveCandidateStatusVariant(status) {
      //console.log("called")
      //const resolveCandidateStatusVariant = status => {
      if (status === 'Uploaded') return 'badge-secondary'
      if (status === 'Initiated') return 'badge-warning'
      if (status === 'ReviewReject') return 'badge-danger'
      if (status === 'ProfileShared') return 'badge-info'
      if (status === 'ScreenReject') return 'badge-danger'
      if (status === 'ScreenSelect') return 'badge-info'
      if (status === 'Inprocess') return 'badge-info'
      if (status === 'On-Hold') return 'badge-warning'
      if (status === 'Shortlisted') return 'badge-success'
      if (status === 'Offered') return 'badge-success'
      if (status === 'NotOffered') return 'badge-danger'
      if (status === 'Joined') return 'badge-success'
      if (status === 'OfferDrop') return 'badge-danger'
      if (status === 'Rejected') return 'badge-danger'
      return 'badge-secondary'
  //}
    },
    assignJob(id) {
      const jid = `SHJ-${parseInt(this.jobId) + 1000}`
      const cid = `SHC-${parseInt(id) + 1000}`
      const c = [];
      c.push(cid)
      const userData = JSON.parse(localStorage.getItem('userData'));

        const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`
        console.log("user", user)
      
      const data = {
        job_id: jid,
        candidate_id: c,
        updated_by: user
      }
      console.log(data)
      JobService.assignCandidateToJob(data).then(res => {
        if(res.status === "OK") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Candidate Added to the Job Successfully',
              icon: 'EditIcon',
              variant: 'success',
              timeout: 5000,
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Candidate Add to Job Failed !!!',
              icon: 'EditIcon',
              variant: 'danger',
              timeout: 5000,
            },
          })
        }
      })
    },
    updateParent(i) {
      console.log(i)
      this.getAllJobs()
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
  },
  mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },
  /*computed: {
		fetchCandidates () {
			return this.filter
				? this.fetchCandidates.find(item => item.first_name.includes(this.filter) || item.last_name.includes(this.filter) || item.email.includes(this.filter) || item.contact_no.includes(this.filter) || item.locations.includes(this.filter) || item.source.includes(this.filter) || item.status.includes(this.filter))
				: this.fetchCandidates
		}
	},*/
  /*
  setup(props) {
    const CANDIDATE_APP_STORE_MODULE_NAME = 'app-candidate'

    const jobId = toRef(props, 'jobId')
     Register module
    if (!store.hasModule(CANDIDATE_APP_STORE_MODULE_NAME)) store.registerModule(CANDIDATE_APP_STORE_MODULE_NAME, candidateStoreModule)

     UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CANDIDATE_APP_STORE_MODULE_NAME)) store.unregisterModule(CANDIDATE_APP_STORE_MODULE_NAME)
    })

    const isAddNewCandidateSidebarActive: false)

    //const jobId: this.jobId)

    const statusOptions = [
      { label: 'Initiated', value: 'initiated' },
      { label: 'Profile Shared', value: 'profile-shared' },
      { label: 'Screen Reject', value: 'screen-reject' },
      { label: 'Screen Select', value: 'screen-select' },
      { label: 'In Process', value: 'inprocess' },
      { label: 'On Hold', value: 'on-hold' },
      { label: 'Selected', value: 'selected' },
      { label: 'Rejected', value: 'rejected' },
      { label: 'Offered', value: 'offered' },
      { label: 'Not Offered', value: 'not-offered' },
      { label: 'Joined', value: 'joined' },
      { label: 'Offer Drop', value: 'offer-drop' },
    ]

    const {
      fetchCandidates,
      //jobId,
      tableColumns,
      perPage,
      currentPage,
      totalCandidates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCandidateListTable,
      refetchData,
      resolveCandidateStatusVariant,      
    } = useCandidatesList()

    return {

      // Sidebar
      isAddNewCandidateSidebarActive,
      jobId,
      fetchCandidates,
      tableColumns,
      perPage,
      currentPage,
      totalCandidates,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCandidateListTable,
      refetchData,

      // Filter

      //avatarText,

      // UI
      // resolveCandidateRoleVariant,
      // resolveCandidateRoleIcon,
      resolveCandidateStatusVariant,

      //roleOptions,
      //planOptions,
      //statusOptions,

      // Extra Filters
      //roleFilter,
      //planFilter,
      //statusFilter,
    }
  },*/
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
